import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';

import { TkCloseButtonComponent } from '@itera-ui-kit/tk-buttons/close-button';

import { toastAlertAnimation } from './animations';
import { Toast, ToastType } from './toast.type';

@Component({
  selector: 'tk-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent, NgClass, TkCloseButtonComponent],
  host: {
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    '[attr.data-type]': 'type()',
    '[@toastAlert]': '',
  },
  animations: [toastAlertAnimation],
})
export class ToastComponent {
  toast = input.required<Toast>();
  /**
   * @internal
   */
  type = computed(() => this.toast().type);
  /**
   * @internal
   */
  icon = computed(() => this.getIcon(this.type()));

  /**
   * @internal
   */
  dismiss = output<void>();

  /**
   * @internal
   */
  close(): void {
    this.dismiss.emit();
  }

  private getIcon(toastType: ToastType): string {
    switch (toastType) {
      case 'positive':
        return 'general:check';
      case 'notice':
        return 'taikun:exclamation-mark';
      case 'negative':
        return 'alerts:alert-triangle';
      case 'informative':
        return 'taikun:info-mark';
      case 'neutral':
        return '';
    }
  }
}
